var render = function () {
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.renderLogin,
    class: _vm.isPublic ? 'auth-wrapper auth-v2 px-2 login-bg' : 'auth-wrapper auth-v1 px-2 login-bg',
    style: {
      'background-image': 'url(' + require('@/assets/images/ptsal/bg1compress.jpeg') + ')'
    }
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_vm.isPublic ? _c('div', {
    staticClass: "navbar-header d-xl-block"
  }, [_c('ul', {
    staticClass: "nav navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }, [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "brand-logo"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.appLogoImage,
      "alt": "logo"
    }
  })], 1)]), _c('b-col', [_c('h2', {
    staticClass: "brand-text ml-20"
  })])], 1)], 1)], 1)])]) : _vm._e(), !_vm.isPublic ? _c('transition', {
    attrs: {
      "name": "slide-fade",
      "mode": "slide-fade"
    }
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('span', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click() {
        _this.isPublic = !_this.isPublic;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ArrowLeftCircleIcon"
    }
  }), _vm._v(" Back ")], 1)], 1), _c('b-link', {
    staticClass: "brand-logo"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h2', {
    staticClass: "brand-text text-primary"
  }, [_vm._v(_vm._s(_vm.title))]), _c('b-col', {
    attrs: {
      "md": "0"
    }
  })], 1)], 1)], 1), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Welcome to " + _vm._s(_vm.welcome) + " ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Please sign-in to your account and start monitoring ")]), _vm.error ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body font-small-2"
  }, [_c('p', {
    domProps: {
      "textContent": _vm._s(_vm.error)
    }
  })]), _c('feather-icon', {
    staticClass: "position-absolute",
    staticStyle: {
      "top": "10",
      "right": "10"
    },
    attrs: {
      "icon": "InfoIcon",
      "size": "18"
    }
  })], 1) : _vm._e(), _c('validation-observer', {
    ref: "loginForm",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-login-form mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.login.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "email",
            "label": "Email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "name": "login-email",
                  "state": errors.length > 0 ? false : null,
                  "placeholder": "user@sal.com"
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "password"
          }
        }, [_vm._v("Password")])]), _c('validation-provider', {
          attrs: {
            "name": "Password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "password",
                  "type": _vm.passwordFieldType,
                  "state": errors.length > 0 ? false : null,
                  "name": "login-password",
                  "placeholder": "Password"
                },
                model: {
                  value: _vm.password,
                  callback: function callback($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIcon
                },
                on: {
                  "click": _vm.togglePasswordVisibility
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.flagAlreadySet === true,
            expression: "flagAlreadySet === true"
          }],
          ref: "btnHaveFun",
          attrs: {
            "id": "btnHaveFun",
            "variant": "primary",
            "type": "submit",
            "block": "",
            "disabled": _vm.loading
          },
          on: {
            "click": function click($event) {
              return _vm.funClick();
            }
          }
        }, [_vm.loading ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" ⓧ Sign IN ⓧ ")], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.flagAlreadySet === false,
            expression: "flagAlreadySet === false"
          }],
          key: _vm.keyNormal,
          attrs: {
            "id": "btnHaveFunMore",
            "variant": "primary",
            "type": "submit",
            "block": "",
            "disabled": _vm.loading
          }
        }, [_vm.loading ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" Sign IN ")], 1)], 1)];
      }
    }], null, false, 1332256434)
  }), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("New on our platform? ")]), _c('span'), _c('b-link', {
    attrs: {
      "to": {
        name: 'auth-register-v1'
      }
    }
  }, [_c('strong', [_vm._v("Create an account")])])], 1), _c('div', {
    staticClass: "login-box"
  }, [_c('form', [_c('a', {
    attrs: {
      "href": "https://landing.salpalaran.com/",
      "target": "_blank"
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" Visit Company Profile ")])])])], 1)], 1) : _vm._e(), _vm.isPublic ? _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('div', [_c('div', {
    staticClass: "login-box"
  }, [_c('b-card', [_c('form', {
    staticClass: "ml-30"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" . ")]), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" . ")]), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" . ")]), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.togglePublicStatus.apply(null, arguments);
      }
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" Login Page ")]), _c('a', {
    attrs: {
      "href": "https://landing.salpalaran.com/",
      "target": "_blank"
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" Visit Company Profile ")])])])], 1), _c('b-card', [_c('div', {
    staticClass: "mt-2 ml-2"
  }, [_c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "TruckIcon"
    }
  }), _vm._v(" Jalur Darat ")], 1)], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "12",
      "cols": "12"
    }
  }, [_c('statistic-card-with-line-chart', {
    key: _vm.componentKey.statistic,
    attrs: {
      "icon": "TruckIcon",
      "color": "danger",
      "statistic": _vm.rtTruckTodayALL,
      "statistic-title": "",
      "chart-data": _vm.data.statisticsOrder.series
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "12",
      "sm": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "danger",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayPatraCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "PATRA",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['PATRA'],
      "current-approvement": _vm.currentApprovement['PATRA']
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "danger",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayTemCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "TEM",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['TEM'],
      "current-approvement": _vm.currentApprovement['TEM']
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "danger",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayPilCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "PIL",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['PIL'],
      "current-approvement": _vm.currentApprovement['PIL']
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "cols": "6"
    }
  }, [_c('statistic-card-vertical', {
    attrs: {
      "hide-chart": "",
      "color": "success",
      "icon": "TruckIcon",
      "statistic": _vm.rtTruckTodayHuluCount,
      "loading": _vm.rtTruckTodayLoading,
      "statistic-title": "HULU",
      "disable-view-limit": _vm.disableViewLimit,
      "max-approvement": _vm.maxApprovement['HULU'],
      "current-approvement": _vm.currentApprovement['HULU']
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HelpCircleIcon"
    }
  }), _vm._v(" HINTS: ")], 1), _vm._v(" \"Login to obtain "), _c('code', [_vm._v("real-time conditions")]), _vm._v(", or "), _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "RefreshCcwIcon"
    }
  }), _vm._v(" simply refresh")], 1), _vm._v(" this page to fetch the latest data.\" ( "), _c('i', [_vm._v("Data ini diload ketika user/anda membuka halaman ini")]), _vm._v(" ). "), _c('hr'), _c('small', [_vm._v("Total Pengguna : "), _c('b-badge', [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "UsersIcon"
    }
  }), _vm._v(" 161")], 1), _vm._v(" | Total Data : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "KeyIcon"
    }
  }), _c('i', [_vm._v("authentication as viewer required")])], 1), _vm._v(" ( "), _c('i', [_vm._v("more than a thousand")]), _vm._v(" ) ")], 1), _vm._v(" | User Access Browser count :  macOS, ⊞ windows, 🤖 android ( Chrome : ( ), Mozila ( ), APK ) ")], 1), _c('div', {
    class: _vm.mode ? 'sal-jetty' : 'sal-jetty-light'
  }, [_c('b-card', {
    staticClass: "sal-title"
  }, [_c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v("JETTY VIEW TODAY")]), _c('small', [_vm._v("Monitoring kondisi Jetty saat ini.")]), _c('br'), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.changeMode();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.mode ? '3D View' : '2D View') + " ")]), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e()], 1)])]), _c('b-row', {
    staticClass: "sal-content row-content justify-content-center match-height"
  }, _vm._l(_vm.jetty, function (jettyItem, jettyKey) {
    return _c('b-col', {
      key: jettyKey,
      staticClass: "column",
      attrs: {
        "md": "2"
      }
    }, [_c('b-card', {
      staticClass: "card-count justify-content-center"
    }, [_c('h5', {
      staticClass: "match-height"
    }, [_vm._v(_vm._s(jettyKey))]), _c('hr'), _c('small', {
      staticClass: "justify-content-center text-center",
      staticStyle: {
        "font-size": "14px"
      }
    }, [_vm._v(" Rencana Sandar: "), _c('b-badge', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top.html",
        value: "🔑 <i>Authentication Required</i>",
        expression: "\"🔑 <i>Authentication Required</i>\"",
        modifiers: {
          "hover": true,
          "top": true,
          "html": true
        }
      }, {
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      attrs: {
        "variant": "primary",
        "content": _vm.content,
        "title": "🔖 Kapal Rencana Sandar"
      }
    }, [_vm._v(" " + _vm._s(jettyItem.rencana_sandar) + " ")]), _c('hr'), _c('strong', [_vm._v("Sandar : ")]), _c('b-badge', {
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v(" " + _vm._s(jettyItem.sandar) + " ")])], 1)])], 1);
  }), 1), _c('b-row', {
    staticClass: "sal-content-sandar row-content justify-content-center"
  }, _vm._l(_vm.jetty, function (jettyItem, jettyKey) {
    return _c('b-col', {
      key: jettyKey,
      staticClass: "column",
      attrs: {
        "md": "2"
      }
    }, _vm._l(jettyItem === null || jettyItem === void 0 ? void 0 : jettyItem.children, function (item, index) {
      return _c('b-card', {
        key: index,
        staticClass: "card-list-item"
      }, [_c('div', {
        staticClass: "row-item"
      }, [_c('div', {
        staticClass: "col-item"
      }, [_c('div', {
        staticClass: "card-item mb-0"
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('b-img', {
        staticClass: "congratulation-medal image",
        staticStyle: {
          "opacity": "0.1"
        },
        attrs: {
          "lazy": "",
          "src": "boat2.png",
          "height": "130"
        }
      })], 1), _c('b-col', [_c('small', [_c('b-badge', {
        attrs: {
          "variant": 'info'
        }
      }, [_c('span', {
        staticStyle: {
          "font-size": "9px"
        }
      }, [_vm._v(" " + _vm._s(item.pemilik) + " ")])]), _c('hr'), _c('small', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "cursor-pointer",
        attrs: {
          "title": "Nomor PKK"
        }
      }, [!item.show_in ? _c('span', {
        staticStyle: {
          "font-size": "9px"
        },
        on: {
          "click": function click($event) {
            return _vm.copyText(item.no_pkk || item.no_pkk_tug_boat);
          }
        }
      }, [_c('strong', [_vm._v(_vm._s(item.no_pkk || item.no_pkk_tug_boat))])]) : _c('span', {
        staticStyle: {
          "font-size": "9px"
        },
        on: {
          "click": function click($event) {
            return _vm.copyText(item.no_pkk_tug_boat);
          }
        }
      }, [_c('strong', [_vm._v(_vm._s(item.no_pkk_tug_boat))])])]), _c('hr'), _c('small', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        attrs: {
          "title": "Nama Kapal"
        }
      }, [!item.show_in ? _c('span', {
        staticStyle: {
          "font-size": "14px"
        }
      }, [_c('strong', [_vm._v(_vm._s(item.boat_name || item.name_tug_boat))])]) : _c('span', {
        staticStyle: {
          "font-size": "14px"
        }
      }, [_c('strong', [_vm._v(_vm._s(item.name_tug_boat))])])]), _c('hr'), _c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        attrs: {
          "title": "Kegiatan"
        }
      }, [_c('strong', {
        staticStyle: {
          "font-size": "11px"
        }
      }, [_vm._v(" " + _vm._s(item.kegiatan) + " ")])]), _c('hr'), item.detail_type === 'form_chain_tbbm' ? _c('span', [_c('b-button', {
        attrs: {
          "variant": _vm.summaryPermission(item, 'TBBM') ? 'outline-danger' : 'outline-primary',
          "disabled": _vm.summaryPermission(item, 'TBBM'),
          "block": "",
          "size": "sm",
          "to": {
            name: 'dashboard-monitoring-rrv-tbbm-summary',
            params: {
              previous_link: {
                name: 'analytic-jalur-sungai-jetty-view'
              },
              id: item.detail_id,
              api: 'form_chain_tbbm'
            }
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": _vm.summaryPermission(item, 'TBBM') ? 'KeyIcon' : 'ActivityIcon'
        }
      })], 1)], 1) : _vm._e(), item.detail_type === 'form_chain_hm' ? _c('span', [_c('b-button', {
        attrs: {
          "variant": _vm.summaryPermission(item, 'HM') ? 'outline-danger' : 'outline-primary',
          "disabled": _vm.summaryPermission(item, 'HM'),
          "block": "",
          "size": "sm",
          "to": {
            name: 'dashboard-monitoring-rrv-hulu-migas-summary',
            params: {
              previous_link: {
                name: 'analytic-jalur-sungai-jetty-view'
              },
              id: item.detail_id,
              api: 'form_chain_hm'
            }
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": _vm.summaryPermission(item, 'HM') ? 'KeyIcon' : 'ActivityIcon'
        }
      })], 1)], 1) : _vm._e(), item.detail_type === 'form_tug_boat' ? _c('span', [_c('b-button', {
        attrs: {
          "variant": _vm.summaryPermission(item, 'FTB') ? 'outline-danger' : 'outline-primary',
          "disabled": _vm.summaryPermission(item, 'FTB'),
          "block": "",
          "size": "sm",
          "to": {
            name: 'dashboard-monitoring-rrv-tug-boat-summary',
            params: {
              previous_link: {
                name: 'analytic-jalur-sungai-jetty-view'
              },
              id: item.detail_id,
              api: ''
            }
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": _vm.summaryPermission(item, 'FTB') ? 'KeyIcon' : 'ActivityIcon'
        }
      })], 1)], 1) : _vm._e()], 1)])], 1)], 1)])])]);
    }), 1);
  }), 1)], 1)], 1)]) : _vm._e(), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_c('small', [_vm._v(" © 2022 - " + _vm._s(new Date().getFullYear()) + " "), _c('a', {
    staticStyle: {
      "color": "white",
      "text-decoration": "none"
    },
    attrs: {
      "href": "https://github.com/yogithesymbian",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" Yogi Arif Widodo ")]), _vm._v(" 🎸 "), _c('a', {
    staticStyle: {
      "color": "white",
      "text-decoration": "none"
    },
    attrs: {
      "href": "https://landing.salpalaran.com/",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" PT Sarana Abadi Lestari ")])])])])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }